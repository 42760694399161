@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxygen&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-family: 'Oxygen', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(15,15,15);
  background: radial-gradient(circle, rgba(15,15,15,1) 0%, rgba(6,6,6,1) 15%, rgba(2,0,36,1) 97%);
}

h1 {
  color : black;
  font-family: 'Krona One', sans-serif;
}

h2,label {
    color: white;
}

.button {
    color: black;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
    transition: 0.4s;
    background-color: white;
    border-radius: 10px;
}
.button:hover{
  background-color: #4CAF50; 
  color: white;
}

a{
  background-color: black;
  color: white;
  padding: .5em 1em;
  text-decoration: none;
  text-transform: uppercase;
}

a:link {
  text-decoration: none;
  border-radius: 5px;
}

.buttonFire{
  color: white;
  background-color: lightcoral;
}
.buttonFire:hover{
  background-color: red;
}
.buttonWater{
  color: white;
  background-color: lightskyblue;
}
.buttonWater:hover{
  background-color: blue;
}
.buttonWind{
  color: white;
  background-color: burlywood;
}
.buttonWind:hover{
  background-color: chocolate;
}
.buttonLight{
  color: black;
  background-color: white;
}
.buttonLight:hover{
  background-color: silver;
}
.buttonDark{
  color: white;
  background-color:purple;
}
.buttonDark:hover{
  background-color:rebeccapurple;
}
.buttonType{
  color: white;
  background-color: black;
}
.buttonType:hover{
  background-color: gray;
}





a:visited {
  text-decoration: none;
}

a:hover {
  background-color: lightblue;
}
.Titletag{
  
  
}

.contact{
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

h5{
  margin: 5%;
}

.spanEstate{
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(6, 2, 78,.8);
}



.title {
  background-color: white;
  display: flex;
  justify-content: space-between;
  position: -webkit-sticky;
  position:sticky;
  top: 0;
  width: 100%;
}


li{
  color: white;
  font-weight: bold;
  list-style-type: none;
  transition: 100ms;
}

li:hover{
  font-size: larger;
}

input{
  cursor: pointer;
}

.ticker {
  height: 15px;
  width: 15px;
  background-color: #eee;
  margin-left: 10px ;
}
.ticker:hover{
  background-color: rgb(63, 61, 61);
}
.ticker:after{
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.general-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.general {
  display: flex;
  justify-content: space-evenly;
}


.fire{
  color : lightcoral;
  font-weight: bold;
}
.water{
  color : lightblue;
  font-weight: bold;
}
.wind{
  color : burlywood;
  font-weight: bold;
}
.light{
  color : white;
  font-weight: bold;
}
.dark{
  color : purple;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
